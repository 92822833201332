/* #Media Queries
================================================== */
/* END__ */

@media (min-width: 992px) and (max-width: 1199px) {
.scroll-content{ padding-left:24px;}
.action-icon{ margin-left:auto;}
} 
/* END__ */

@media (min-width: 768px) and (max-width: 991px) {
/*.scroll-content{ padding:30px; position:relative; bottom:auto; top:auto; left:auto; right:0; height:600px;}*/
.action-icon{ margin-left:auto;}
.action-button{ position:relative; right:auto; left:auto; top:auto; padding:30px; margin-bottom:0;}
.notes { margin-top:0;}
.footer{ display:block !important; text-align:center;}
.footer .menu li{ display:inline-block; margin:0 5px 10px; float:none;}
.footer .menu li + li{ margin-left:5px;}
.sidebar-nav, .wrapper-content .content{ height:500px;}
.scroll-nav{ height:400px;}
.wrapper-content .scroll-content, .scroll-content{ height:600px;}
.action-icon > li:first-child{ display:none;}

} 
/* END__ */

@media only screen and (max-width: 767px) {
body{ font-size:15px;}
.action-icon{ margin-left:auto;}
.action-button{ position:relative; right:auto; left:auto; top:auto; padding:30px; margin-bottom:0;}
.notes { margin-top:0;}
.footer{ display:block !important; text-align:center;}
.footer .menu li{ display:inline-block; margin:0 5px 10px; float:none;}
.footer .menu li + li{ margin-left:5px;}
.sidebar-nav, .wrapper-content .content{ height:auto;}
.scroll-nav{ height:auto;}
.wrapper-content .scroll-content, .scroll-content{ height:500px;}
.action-icon > li:first-child{ display:none;}
} 
/* END__ */

@media (max-width: 480px) {
} 
/* END__ */

