.App {
  text-align: center;
}
p { 
  margin-top: 0;
    margin-bottom: 0;
 }

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

/* @font-face {
  font-family: 'Default';
  src: local('Default'), url(./assets/webfonts/indo) format('truetype');
} */

@font-face {
  font-family: 'IndoPak';
  src: local('IndoPak'), url(./assets/webfonts/trado.ttf) format('truetype');
}

@font-face {
  font-family: 'Uthmani';
  src: local('Uthmani'), url(./assets/webfonts/UthmanicHafs1Ver09.otf) format('opentype');
}

@font-face {
  font-family: 'Tajweed';
  src: local('Tajweed'), url(./assets/webfonts/PDMS_SALEEM_QURANFONTQESHIP_0.ttf) format('truetype');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.reference {
  cursor: pointer;
  font-size: 10px;
  font-weight: 600;
  color:rgb(35, 111, 197)
}

.drawer-item {
  font-size: 10px;
}

.ayahNumberEng {
  font-size: 14px;
}


.ayahNumber {
  padding: 0 .5em;
  display: inline-block;
  position: relative;
}

.ayahNumber::after {
  content: "\06DD";
  display: block;
  position: absolute;
  font-size: 1.5em;
  top: 50%;
  left: 50%;
  transform: translate( -50%, -50%);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.title {
  line-height: 1.6;
  font-size: 24px;
  font-weight: 800;
}
.titleArabic {
  line-height: 1.6;
  font-size: 24px;
  font-weight: 800;
}
.topic {
  line-height: 1.6;
  font-size: 20px;
  font-weight: 800;
}
.subTopic {
  line-height: 1.6;
  font-size: 17px;
  font-weight: 800;
}

/* .transiliteration {
  line-height: 1.6;
  font-size: 16px;
  font-weight: 800;
}
.translation{
  line-height: 1.6;
  font-size: 14px;
  font-weight: 500;
}
.arabic {
  line-height: 1.6;
  font-size: 18px;
  font-weight: 800;
} */

