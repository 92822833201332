@charset "utf-8"; /* CSS Document */
ul:before, ul:after{ content:''; display:table;}
ul:after{ clear:both;}
.full-img img{ width:100%; min-height:300px;}
img.img-crop { display:block; max-width:none }
a, button, input[type=submit], .btn, img{-moz-transition:all 0.5s ease; -webkit-transition:all 0.5s ease; -o-transition:all 0.5s ease; transition:all 0.5s ease;}
.btn .btn-primary {
    background: #c6ba81;
}

.header{ padding:0 33px; background:url(images/header-bg-white.png) no-repeat left rgba(242,242,242,0.4); padding:0 33px; height:100px; position:relative; z-index:1000}
.header .menu-open{ background:url(images/menu-b.png) no-repeat center; background-size:100%; height:32px; width:41px; border:none; cursor:pointer; padding:0;}
.header .logo { color:var(--bs-black); font-weight:700; font-size:24px; margin-left:32px;}
.header .logo .logo-text{ margin-left:18px;}
.action-icon { margin:0 0 0 26px; padding:0; list-style:none;}
.action-icon > li{ float:left; position:relative;}
.action-icon > li + li{ margin-left:40px;}
.action-icon > li a, .action-icon > li button{ display:block; height:26px; width:26px; background-size:100%; background-position:center; background-repeat:no-repeat; background-color:inherit; border:none; padding:0;}
.action-icon > li a.user{ background-image:url(images/user-b.png);}
.action-icon > li a.settings{ background-image:url(images/settings-b.png);}
.action-icon > li button.dropdown-toggle{ background-image:url(images/split-screen.png);}
.action-icon > li button.dropdown-toggle::after { display:none}
.action-icon .setting-screen{ position:absolute; right:0; top:100%; width:266px;}
.action-icon .setting-screen .card-body{ border-radius:0; background:var(--bs-white); border:1px solid #f1f1f1;}
.action-icon .setting-screen .card-body .items + .items{ border-top:1px solid #f1f1f1; margin-top:24px; padding-top:24px;}
.color-swatches { margin:0; padding:0; list-style:none;}
.color-swatches li{ font-size:13px; text-align:center;}
.color-swatches li + li{ margin-left:auto;}
.color-swatches li button{ margin:0; padding:0; background:none; outline:none; border:none; cursor:pointer;}
.color-swatches li button span{ display:block; height:37px; width:37px; border-radius:6px; margin-bottom:5px; border:1px solid transparent}
.color-swatches li button.white span{ background-color:#fff; border-color:#f1f1f1;}
.color-swatches li button.sepia span{ background-color:#f3eed9; border-color:#c6ba81;}
.color-swatches li button span.black{ background-color:#000000; border-color:#000000;}

.action-icon .dropdown-menu button{display:block; height:26px; width:26px; background-size:100%; background-position:center; background-repeat:no-repeat; background-color:inherit; border:none; padding:0; margin:0 5px;}
.action-icon .dropdown-menu button.one-col{ background-image:url(images/one-col.png);}
.action-icon .dropdown-menu button.two-col{background-image:url(images/two-col.png);}
.action-icon .dropdown-menu button.three-col{background-image:url(images/three-col.png);}
.action-icon .dropdown-menu{ padding:15px;}

.wrapper-home.active .second-col{ display:none;}
.wrapper-home.active .first-col{ width:100%;}
.wrapper-page.two-active .second-col{width:83.33333333%;}
.wrapper-home.active .scroll-content-full .content, .wrapper-page.active .scroll-content .content, .wrapper-page.two-active .scroll-content .content, .wrapper-page.active.two-active .scroll-content .content{ max-width:100%;}

.wrapper-page.active .second-col{ width:100%;}
.wrapper-page.active .first-col, .wrapper-page.active .third-col{ display:none;}

.wrapper-page.two-active .three-col{display:none;}



.checkbox-wrapper input[type="checkbox"] {display:none; visibility:hidden; }
.checkbox-wrapper {--size:40px; margin-left:auto; }
.checkbox-wrapper .block {position:relative; clear:both; float:left; }
.checkbox-wrapper label {width:var(--size); height:22px; box-sizing:border-box; border:2px solid #bbbab8; float:left; border-radius:100px; position:relative; cursor:pointer; transition:.3s ease; color:black; }
.checkbox-wrapper input[type=checkbox]:checked + label {background:#fff; border:2px solid #dea90d; }
.checkbox-wrapper input[type=checkbox]:checked + label:before {left:calc(var(--size) / 2); }
.checkbox-wrapper label:before {transition:.3s ease; content:''; width:calc((var(--size) / 2) - 6px); height:calc((var(--size) / 2) - 6px); position:absolute; background:white; left:2px; top:2px; box-sizing:border-box; border:2px solid #bbbab8; background:#bbbab8; color:black; border-radius:100px; }
.checkbox-wrapper input[type=checkbox]:checked + label:before{border:2px solid #dea90d; background:#dea90d;}
.txt-setting .d-flex{ font-size:13px;}
.txt-setting .d-flex + .d-flex{ margin-top:12px;}
.txt-setting .d-flex .form-select{ padding:5px 10px; font-size:13px; width:120px; margin-left:auto;}
.action-icon .setting-screen .card-body h6{ color:#000; margin-bottom:12px;}
.lay-setting{ margin:0; padding:0; list-style:none;}
.lay-setting li{ float:left;}
.lay-setting li + li{ margin-left:5px; margin-bottom:5px;}
.lay-setting li button{ display:block; border:1px solid #dbdad9; border-radius:4px; padding:2px 5px;  font-size:12px; background:none;}
.lay-setting li button:hover{ border-color:#d9b711; background:#fbf5e2; color:#403c37;}

.notes .title{ padding:24px 40px; color:var(--bs-white); background:var(--bs-yellow);}
.notes .title h5{ margin:0; padding:0;}

.scroll-content{ height:calc(100vh - 172px)}
.scroll-content .content{ margin:0 auto; width:100%; max-width:950px; padding:30px}
.scroll-content::-webkit-scrollbar {width:10px;}
.scroll-content::-webkit-scrollbar-track {/*-webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3);*/}
.scroll-content::-webkit-scrollbar-thumb {background-color:#e4e4e4; outline:none; border-radius:20px;}
.scroll-content h6{ font-weight:400; color:var(--bs-yellow); margin-bottom:30px;}
.scroll-content h3{ color:#202647; margin-bottom:30px;}

.footer{ padding:30px 33px;font-size:14px; font-family:'Montserrat', sans-serif;}
.footer .menu{ margin-left:auto; margin-bottom:0; padding:0; list-style:none;}
.footer .menu li{ float:left;}
.footer .menu li + li{ margin-left:20px;}
.footer .menu a{ color:#6b6b84;}
.footer .menu a:hover{ color:#000;}

.offcanvas-menu{ padding:0; border-top:1px solid }
.offcanvas-menu .menu{ margin:0; padding:0; list-style:none;border-bottom:1px solid #d8d8d8;}
.offcanvas-menu .menu li + li{ border-top:1px solid #d8d8d8;}
.offcanvas-menu .menu li a{ display:block; padding:14px 35px; font-family:'Montserrat', sans-serif; font-weight:700; color:#6b6b84;}
.offcanvas-menu .menu li a:hover{ color:#fff; background:var(--bs-yellow);}
.top-btn{ height:72px; padding:0 30px; background:#fff;}
.action-button{ margin:0; padding:0; list-style:none; display:flex;}
.action-button li a{ display:block; height:50px; width:50px; border-radius:6px; background-size:24px; background-position:center; background-repeat:no-repeat; border:1px solid transparent;}
.action-button li a.sound{ background-image:url(images/volume.png);}
.action-button li a.save{ background-image:url(images/bookmark.png);}
.action-button li a.note{ background-image:url(images/notes.png);}
.action-button li a.split{ background-image:url(images/split-screen.png); border-color:var(--bs-yellow);}
.wrapper-content .title{ width:auto; padding:20px 0 0 0; color:#202647;}
.wrapper-content .title h3{ margin-bottom:0;}
.wrapper-content .title .arabic-text{ font-size:24px;}
.wrapper-content .scroll-content{height:calc(100vh - 194px);}

.wrapper-content .content .item .arabic-text{ white-space:nowrap;}
.wrapper-content .content .item{color:#202647;}
.wrapper-content .content .item:hover h3{ color:#202647; color:var(--bs-yellow);}
.wrapper-content .content .item + .item .d-md-flex { margin-top:30px; padding-top:30px; border-top:1px solid #d8d8d8;}

.scroll-nav{height:calc(100vh - 100px);}
.sidebar-nav ul{ margin:0; padding:0; list-style:none;}
.sidebar-nav ul li{ border-bottom:1px solid #d8d8d8;}
.sidebar-nav ul li a{ padding:10px 30px; display:block; color:#202647; font-weight:600; font-size:18px;}
.sidebar-nav ul li a:hover{color:var(--bs-yellow);}

body.sepia{ background-color:#f3eed9;}
body.sepia .header{ padding:0 33px; background:url(images/header-bg-sepia.png) no-repeat left rgba(253,252,247,0.4);}
body.sepia .footer{ border-color:#eae5cf; background:#f3eed9;}
body.sepia .action-icon .setting-screen .card-body{ background:#f3eed9; border-color:#e1dcc8;}
body.sepia .action-icon .setting-screen .card-body .items + .items{ border-color:#e1dcc8;}

body.black{ background-color:#000; color:#fff;}
body.black .header{ padding:0 33px; background:url(images/header-bg-black.png) no-repeat left #191919;}
body.black .footer{ border-color:#050505; background:#000;}
body.black .action-icon .setting-screen .card-body{ background:#000; border-color:#000; color:#fff;}
body.black .action-icon .setting-screen .card-body .items + .items{ border-color:#000;}
body.black .menu-open{ background:url(images/menu-w.png) no-repeat center; background-size:100%;}
body.black .logo .logo-text{ color:#fff;}
body.black .offcanvas{ background:#000;}
body.black .action-icon > li a.user{ background-image:url(images/user-w.png);}
body.black .action-icon > li a.settings{ background-image:url(images/settings-w.png);}
body.black .offcanvas-menu .menu li a{color:#fff;}
body.black .offcanvas-header{ color:#fff;}
body.black .offcanvas-menu .menu li + li, body.black .offcanvas-menu .menu, body.black .offcanvas-menu .menu li + li, body.black .offcanvas-menu .menu{border-color:rgba(255,255,255,0.2);}
body.black .lay-setting li button, body.black .action-icon .setting-screen .card-body h6, body.black .color-swatches li label, body.black .scroll-content h3{ color:#fff;}
body.black .action-icon .setting-screen .card-body .items + .items, body.black .color-swatches li button span.black{ border-color:#303030;}
body.black .stellarnav li a, body.black .footer .menu a{color:var(--bs-white);}
body.black .stellarnav li a:hover, body.black .footer .menu a:hover{color:var(--bs-yellow);}
body.black .stellarnav > ul > li > a{color:var(--bs-white); border:none;}
body.black .stellarnav > ul > li > a:hover{color:var(--bs-yellow);}
body.black .action-button li a.sound{ background-image:url(images/volume-w.png);}
body.black .action-button li a.save{ background-image:url(images/bookmark-w.png);}
body.black .action-button li a.note{ background-image:url(images/notes-w.png);}
body.black .action-button li a.split{ background-image:url(images/split-screen-w.png);}
body.black .action-icon > li button.dropdown-toggle{ background-image:url(images/split-screen-w.png);}

body.black .wrapper-content .title,
body.black .sidebar-nav ul li a, 
body.black .wrapper-content .content .item{ color:#fff;}

body.black .sidebar-nav ul li a:hover, 
body.black .wrapper-content .content .item .arabic-text{color:var(--bs-yellow);}

body.black .wrapper-content .content .item + .item .d-md-flex, 
body.black .sidebar-nav ul li { border-color:rgba(255,255,255,0.2)}

@media(min-width:768px){
.wrapper-content .content .item h3{ font-size:28px;}
}

@media(min-width:1200px){
.scroll-content h3{ font-size:28px;}
}

.section{ padding:70px 0;}
.section-color{ background:#edf4f4}
.sidebar-nav ul{ background:var(--bs-primary); border-radius:10px; padding:0; list-style:none;}
.sidebar-nav ul li + li{ border-top:1px solid rgba(255,255,255,0.2); }
.sidebar-nav ul li a{ color:var(--bs-white); padding:14px 20px; display:block; text-decoration:none; font-weight:600;}
.sidebar-nav ul li a:hover{ color:#000; text-decoration:none;}


.footer{ padding:24px 0;}
.footer .copyright{ order:1;}
.footer .menu{ order:2; margin-left:auto;}
.footer .menu ul{ margin:0; padding:0; list-style:none;}
.footer .menu ul li{ display:inline-block;}
.footer .menu ul li + li{ margin-left:15px;}
.footer a{ color:#6b6b84; text-decoration:none;}
.footer a:hover { color:#000;}
.page-title, .resource-item h3{ color:#202647;}
.resource-item h3{ margin:0;}
.resource-item h4{ color:#819ead;}
.resource-item h3, .resource-item h4{ font-weight:600;}

.donate-form input[type="radio"] { display:none;}
.donate-form input[type="radio"] + label { color:#202647; display:block; margin:0; cursor:pointer;  font-family:'Oswald', sans-serif; font-size:28px; padding:10px 0; text-align:center; border-radius:10px; border:2px solid var(--bs-primary);}
.donate-form input[type="radio"]:checked + label { background:var(--bs-primary); color:var(--bs-white);}
.donate-form [class*=col-]{ width:20%;}
.donate-form .other{font-size:28px; padding:10px 0; text-align:center; border-radius:10px; border:2px solid var(--bs-primary);color:#202647; display:block;}
.title-color, .past-donation h4{color:#202647;}
.past-donation h4{ margin-bottom:24px;}
.past-donation [class*=col-] .banner{ position:relative;}
.past-donation [class*=col-] .banner .image-fit{ height:162px;}
.past-donation [class*=col-] .banner .content{ position:absolute; background:rgba(16,16,16,0.4); color:#fff; font-size:18px; font-weight:600; text-align:center; z-index:10; top:0; left:0; right:0; bottom:0;}
.home-w1 { text-align:center;}
.home-w1 h4{color:#202647;}


.banner{ position:relative;}
.banner .image-fit{ height:984px;}
.banner .container{ top:50%; left:50%; -webkit-transform:translate(-50%, -50%); -ms-transform:translate(-50%, -50%); transform:translate(-50%, -50%); color:#fff; position:absolute; z-index:10; text-align:center;}
.banner .container .content{ max-width:650px; margin-left:auto;}
.banner .container img{ display:block; margin:0 auto 5%;}

.newsletter{  text-align:center;}
.newsletter .content{ max-width:600px; margin:0 auto;}
.newsletter form{ display:block; position:relative; margin-bottom:40px;}
.newsletter form .btn{ position:absolute; top:0; right:0; border-radius:0 0.375rem 0.375rem 0}
.newsletter form .form-control{ border-color:#fff;}
.newsletter h4{color:#202647; font-family:'Merriweather', serif; text-align:center; margin-bottom:24px;}
.newsletter .social{ margin:0; padding:0; list-style:none;}
.newsletter .social li{ padding:0 10px; font-size:24px;}
.newsletter .social li a{ color:#c4c4c4;}
.newsletter .social li a:hover{ color:var(--bs-primary);}
